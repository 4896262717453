import styled from 'styled-components';
import { grid } from 'styled-system';
import css from '@styled-system/css';

import Box, { boxProps } from 'humanity/primitives/box';

const Grid = styled(Box)(
  css({
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: [3, null, 5],
  }),
  grid
);

Grid.displayName = 'Grid';

Grid.propTypes = {
  ...boxProps,
};

export default Grid;
