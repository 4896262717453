import { ForwardedRef, forwardRef, SelectHTMLAttributes } from 'react';
import { ChevronDown } from 'react-dripicons';

import Input from 'humanity/primitives/input';

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  hasError?: boolean;
  inputId: string;
  options: Array<{
    disabled?: boolean;
    hidden?: boolean;
    label?: string;
    sys?: {
      id?: string;
    };
    value?: string;
  }>;
};

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ options, inputId, hasError = false, ...props }: Props, ref) => (
    <Input
      RightIcon={ChevronDown}
      as="select"
      data-testid="Select"
      ref={ref as ForwardedRef<HTMLInputElement>}
      id={inputId}
      hasError={hasError}
      {...props}
    >
      {options?.map((option) => (
        <option
          key={option.sys?.id ?? option.value}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.label}
        </option>
      ))}
    </Input>
  )
);

Select.displayName = 'Select';

export default Select;
