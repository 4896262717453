import { ComponentProps, FC, forwardRef, ReactNode } from 'react';
import css, { SystemStyleObject } from '@styled-system/css';
import styled, { useTheme } from 'styled-components';

import { Theme } from '../../../utils/theme.v2';

import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';

export const baseInputStyles = ({
  hasError,
  hasLeftIcon,
  hasRightIcon,
}: {
  hasError?: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
}): SystemStyleObject => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: hasError ? 'redError' : 'blue20',
  borderRadius: 'sm',
  width: '100%',
  height: '3rem',
  px: 3,
  pl: hasLeftIcon ? 6 : 3,
  pr: hasRightIcon ? 6 : 3,
  py: 2,
  bg: 'white',
  backgroundOrigin: hasError ? 'content-box' : 'border-box',
  fontSize: 'bodySmall',
  fontFamily: 'body',
  color: 'blue100',
  appearance: 'none',
  '&:focus': {
    outlineColor: hasError ? 'redError' : 'teal100',
  },
  // This will prevent iOS from zooming on input focus
  '@supports (-webkit-overflow-scrolling: touch)': {
    // hard-coding this value, since we always want this actual font size
    fontSize: '16px',
  },
});

const IconWrapper = styled(Flex)<
  ComponentProps<typeof Flex> & { left?: boolean; right?: boolean }
>((props) =>
  css({
    position: 'absolute',
    top: 0,
    left: props.left ? 3 : 'auto',
    right: props.right ? 3 : 'auto',
    justifyContent: 'start',
    alignItems: 'center',
    height: '100%',
    pointerEvents: 'none',
  })
);

type InputProps = ComponentProps<typeof Box> & {
  ctaButton?: ReactNode;
  LeftIcon?: FC<{ size: number; color: string }>;
  RightIcon?: FC<{ size: number; color: string }>;
  hasError?: boolean;
  icon?: ReactNode;
};

const Input = forwardRef<HTMLDivElement, InputProps>(
  ({ ctaButton, LeftIcon, RightIcon, hasError = false, ...props }: InputProps, ref) => {
    const theme = useTheme() as Theme;
    if (!LeftIcon && !RightIcon && !ctaButton) {
      return (
        <Box as="input" ref={ref} sx={{ ...baseInputStyles({ hasError }) }} {...props} />
      );
    }

    return (
      <Box
        sx={{
          width: '100%',
          ...(ctaButton !== undefined
            ? {
                display: [null, 'flex'],
                flexDirection: [null, 'row'],
              }
            : {}),
        }}
      >
        <Box
          sx={{
            position: 'relative',
            cursor: 'pointer',
            width: '100%',
            '& > *': { cursor: 'pointer' },
          }}
        >
          {LeftIcon && (
            <IconWrapper left>
              <LeftIcon size={28} color={theme.colors.blue50} />
            </IconWrapper>
          )}
          <Box
            as="input"
            ref={ref}
            sx={{
              ...baseInputStyles({
                hasError,
                hasLeftIcon: !!LeftIcon,
                hasRightIcon: !!RightIcon,
              }),
              ...(ctaButton !== undefined
                ? {
                    borderBottomRightRadius: [null, 0, null, null],
                    borderTopRightRadius: [null, 0, null, null],
                  }
                : {}),
            }}
            {...props}
          />
          {RightIcon && (
            <IconWrapper right>
              <RightIcon size={28} color={theme.colors.blue50} />
            </IconWrapper>
          )}
        </Box>
        {ctaButton !== undefined && ctaButton}
      </Box>
    );
  }
);

Input.displayName = 'Input';

export default Input;
