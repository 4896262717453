import axios from 'axios';
import queryString from 'query-string';

export default class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(id) {
    const res = await axios.get(`${this.baseUrl}/${id}/`);

    return res.data;
  }

  async find(filters) {
    const search = queryString.stringify(filters, { arrayFormat: 'comma' });

    const res = await axios.get(`${this.baseUrl}?${search}`);

    return res.data;
  }

  async create(data) {
    const res = await axios.post(this.baseUrl, data);

    return res.data;
  }

  async update(id, data) {
    const res = await axios.patch(`${this.baseUrl}/${id}/`, data);

    return res.data;
  }
}
