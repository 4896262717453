const map = {
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'Email',
  phone: 'Phone',
  company: 'Company',
  employees: 'Employees__c',
  employeesRange: 'Mobile_EE_Bucket__c',
  role: 'Title',
  payrollProvider: 'Payroll_Provider__c',
  currentPlan: 'Current_Plan_Type__c',
  conversionPlan: 'Conversion__c',
  selectedPlan: 'Selected_Plan__c',
  offerCode: 'Offer_Code__c',
  isNonProfit: 'Non_Profit__c',
  ownerHasOtherBusinesses: 'Owner_has_other_businesses__c',
  variantId: 'VariantId__c',
  gclid: 'GCLID__c',
  msclkid: 'MSCLKID__c',
  irclickid: 'irclickid',
  utm_campaign: 'pi__utm_campaign__c',
  utm_content: 'pi__utm_content__c',
  utm_medium: 'pi__utm_medium__c',
  utm_source: 'pi__utm_source__c',
  utm_term: 'pi__utm_term__c',
  leadSource: 'LeadSource',
  segmentAnonymousId: 'Segment_Anonymous_ID__c',
  formFillPage: 'Form_Fill_Page__c',
  zip_code__c: 'zip_code__c',
  relationship_to_hi: 'relationship_to_hi',
};

const convertLeadToSfdc = (lead) => {
  if (!lead) return null;

  const sfdcLead = {};

  Object.keys(lead).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!map.hasOwnProperty(key)) return;
    sfdcLead[map[key]] = lead[key];
  });

  return sfdcLead;
};

export default convertLeadToSfdc;
