import Text from 'humanity/primitives/text';

const InputError = (props) => (
  <Text
    data-testid="InputError"
    variant="label"
    mt={1}
    pl={1}
    color="redError"
    {...props}
  />
);

export default InputError;
