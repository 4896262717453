import { useRouter } from 'next/router';

import hubspot from 'apiClients/hubspot';
import { track } from 'utils/analytics';
import getOrSetTrackingParams from 'utils/getOrSetTrackingParams';
import convertLeadToSfdc from 'utils/convertLeadToSfdc';
import useAnalytics from 'hooks/useAnalytics';
import { getSessionData } from 'utils/getSessionData';
import { logger } from 'utils/logger';

/**
 * Submits the form to Hubspot
 * @function hubspotSubmitForm
 * @param {object} values The form values object passed from Formik in the onSubmit handler
 */

/**
 * Generates a submit handler function for a given Hubspot form ID.
 *
 * @param {String} hubspotFormId The Hubspot Production form ID
 * @param {String} hubspotSandboxFormId The Hubspot Sandbox form ID
 * @param {String} salesforceCampaignId The Salesforce Campaign ID
 * @param {String} formType The formType set in Contentful
 */
export const useHubspotForm = (
  hubspotFormId,
  hubspotSandboxFormId,
  salesforceCampaignId,
  formType = ''
) => {
  const router = useRouter();
  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();

  const submitForm = async (values, trackedEvent = null) => {
    const trackingParams = getOrSetTrackingParams(router.query);
    // eslint-disable-next-line camelcase
    const { referrer, landing_page } = getSessionData();

    try {
      const userData = {
        ...trackingParams,
        ...values,
        leadSource: 'Inbound',
        referrer,
        // eslint-disable-next-line camelcase
        landing_page,
        form_fill_page__c: window.location.pathname,
        form_type: formType?.toLowerCase() || '',
      };

      const form = convertLeadToSfdc(userData);

      const hubspotData = {
        form: {
          ...form,
          // TODO: This is a quick fix to get these properties in the HS form
          // submission without breaking anything else.
          // We will want to standardize these properties on the app storage side
          // and add separate layers for converting them for each destination, so
          // it's not a mess like it currently is.
          leadSource: 'Inbound',
          referrer,
          // eslint-disable-next-line camelcase
          landing_page,
          form_fill_page__c: window.location.pathname,
          form_type: formType?.toLowerCase() || '',
        },
        formId:
          process.env.NEXT_PUBLIC_HI_ENV === 'production'
            ? hubspotFormId
            : hubspotSandboxFormId,
        salesforceCampaignId,
        pageUrl: `${process.env.NEXT_PUBLIC_HI_BASE_URL}${router.asPath}`,
      };

      track('form_submitted', {
        ...userData,
      });

      if (trackedEvent) {
        track(trackedEvent, {
          ...userData,
          hinAnonymousId,
        });
      }

      await hubspot.submitForm(hubspotData);
    } catch (err) {
      logger.error(`Error submitting form to Hubspot`, err, err.response?.data);
      throw err;
    }
  };

  return { submitForm };
};
