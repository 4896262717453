import PropTypes from 'prop-types';

import { ContentfulSys, contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'date', 'select', 'radio'])
    .isRequired,
  label: PropTypes.string.isRequired,
  autocompleteValue: PropTypes.string,
  required: PropTypes.bool,
};

export const inputOptionType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const formikInputType = PropTypes.shape({
  ...commonProps,
  fieldName: PropTypes.string.isRequired,
  optionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(inputOptionType),
  }),
});

export type ContentfulInput = {
  autocompleteValue?: string;
  fieldName: string;
  label: string;
  optionsCollection?: {
    items: {
      sys: ContentfulSys;
      label: string;
      value: string;
    }[];
  };
  required?: boolean;
  type: 'text' | 'number' | 'email' | 'tel' | 'date' | 'select' | 'radio';
};
