import { css } from 'styled-components';
import { FC } from 'react';

import { type BoxProps } from '../../primitives/box/box';

import { fadeIn } from 'utils/animations';
import Box from 'humanity/primitives/box';

const width = {
  third: ['100%', null, '32%'],
  half: ['100%', null, '47.5%'],
  full: '100%',
};

const animation = css`
  animation: ${fadeIn} 0.7s ease;
`;

const FormGroup: FC<
  BoxProps & { span?: 'third' | 'half' | 'full' }
> = ({ span = 'full', ...props }) => (
  <Box
    {...props}
    sx={{
      mt: 2,
      width: width[span],
      flexBasis: width[span],
    }}
    // the keyframe animation needs to set in this prop to be injected properly
    css={animation}
  />
);

FormGroup.displayName = 'FormGroup';

export default FormGroup;
