import { ComponentProps, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { IMaskInput } from 'react-imask';

import { baseInputStyles } from 'humanity/primitives/input';

const Input = styled(IMaskInput)<
  HTMLAttributes<HTMLInputElement> & { hasError?: boolean }
>(({ hasError }) =>
  css({
    ...baseInputStyles({ hasError }),
  })
);

type Props = ComponentProps<typeof Input> & {
  hasError?: boolean;
};

const PhoneInput = forwardRef<HTMLInputElement, Props>(
  ({ hasError = false, ...props }: Props, ref) => (
    <Input
      data-testid="PhoneInput"
      ref={ref}
      type="tel"
      mask="(000) 000-0000"
      hasError={hasError}
      {...props}
    />
  )
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
