import axios from 'axios';

import ApiClient from 'apiClients';

class HubspotClient extends ApiClient {
  constructor() {
    super(`/api/hubspot`);
  }

  /**
   *
   * @param {Object} data The request body to be sent to Hubspot
   */
  async submitForm(data) {
    return axios.post(`${this.baseUrl}/submitForm/`, data);
  }
}

export default new HubspotClient();
