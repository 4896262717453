import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { ComponentProps, FC } from 'react';

import { SimpleRichTextType } from '../simpleRichText/simpleRichText.types';
import { Theme } from '../../../utils/theme.v2';

import SimpleRichText from 'humanity/components/simpleRichText';
import Box from 'humanity/primitives/box';
import Link from 'humanity/primitives/link';
import Text from 'humanity/primitives/text';

const StyledList = styled(Box)(
  css({
    display: 'inline-block',
    ml: '2em',
    textAlign: 'left',
    fontSize: 'small',
    lineHeight: 'small',
  })
);

const StyledListItem = styled.li(
  css({
    mt: 2,
    '&::marker': {
      fontSize: 'small',
      color: 'blue100',
    },
    '& p': {
      mt: 0,
    },
    '& b, & strong': {
      fontWeight: 600,
      color: 'blue100',
    },
  })
);

const renderOpts = (fontColor) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text variant="small" color={fontColor}>
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <StyledList as="ul">{children}</StyledList>,
    [BLOCKS.OL_LIST]: (node, children) => <StyledList as="ol">{children}</StyledList>,
    [BLOCKS.LIST_ITEM]: (node, children) => <StyledListItem>{children}</StyledListItem>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link
        href={node.data.uri}
        color={fontColor === 'blue100' ? 'teal100' : 'blue100'}
        target="_blank"
      >
        {children}
      </Link>
    ),
  },
});

type Props = ComponentProps<typeof Box> & {
  disclosures: SimpleRichTextType;
  fontColor?: keyof Theme['colors'];
};

const Disclosures: FC<Props> = ({ disclosures, fontColor = 'blue100', ...props }) => (
  <Box {...props}>
    <SimpleRichText content={disclosures.json} renderOptions={renderOpts(fontColor)} />
  </Box>
);

export default Disclosures;
