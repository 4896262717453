import * as yup from 'yup';

/**
 * @typedef {Object} Input
 * @property {string} type
 * @property {string} fieldName
 * @property {string} label
 * @property {boolean} required
 */

/**
 *
 * @param {Input[]} inputs
 * @returns
 */
export const buildDefaultValidationSchema = (inputs) => {
  const schemaObject = {};

  inputs.forEach((input) => {
    // handle email and phone inputs specially
    if (input.type === 'email') {
      schemaObject[input.fieldName] = yup
        .string()
        .email('Email must be valid')
        .required(`${input.label} is required`);
    } else if (input.type === 'tel') {
      schemaObject[input.fieldName] = yup
        .string()
        .matches(
          /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g,
          'Please enter a valid phone number'
        )
        .required(`${input.label} is required`);
      // anything else just mark as a required string
    } else if (input.fieldName === 'employees') {
      schemaObject[input.fieldName] = yup
        .number()
        .positive('Please enter a number greater than 0')
        .integer('Please enter an integer')
        .required(`${input.label} is required`)
        .typeError('Please enter a number');
    } else if (input.required) {
      schemaObject[input.fieldName] = yup.string().required(`${input.label} is required`);
    } else {
      schemaObject[input.fieldName] = yup.string();
    }
  });

  return yup.object(schemaObject);
};
