import PropTypes from 'prop-types';

import { formikInputType } from 'humanity/components/formikInput/formikInput.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disclosures: simpleRichTextType,
  submitButtonVariant: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export const contentfulFormType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  inputsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(formikInputType).isRequired,
  }),
});
